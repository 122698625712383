// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-en-ceremony-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/en/ceremony.js" /* webpackChunkName: "component---src-pages-en-ceremony-js" */),
  "component---src-pages-en-contact-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-info-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/en/info.js" /* webpackChunkName: "component---src-pages-en-info-js" */),
  "component---src-pages-en-party-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/en/party.js" /* webpackChunkName: "component---src-pages-en-party-js" */),
  "component---src-pages-en-toastmasters-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/en/toastmasters.js" /* webpackChunkName: "component---src-pages-en-toastmasters-js" */),
  "component---src-pages-index-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-kontakt-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-middag-fest-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/middag-fest.js" /* webpackChunkName: "component---src-pages-middag-fest-js" */),
  "component---src-pages-toastmasters-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/toastmasters.js" /* webpackChunkName: "component---src-pages-toastmasters-js" */),
  "component---src-pages-vigsel-js": () => import("/Users/victor/c/victorandree/victor-mal.in/src/pages/vigsel.js" /* webpackChunkName: "component---src-pages-vigsel-js" */)
}

