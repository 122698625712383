module.exports = [{
      plugin: require('/Users/victor/c/victorandree/victor-mal.in/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139402984-1"},
    },{
      plugin: require('/Users/victor/c/victorandree/victor-mal.in/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Libre Baskerville:400,400i","Just Another Hand"]},"typekit":{"id":"fns0atl"}},
    },{
      plugin: require('/Users/victor/c/victorandree/victor-mal.in/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
